import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {Route } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import Orders from './pages/orders';
import Customers from './pages/customers';
import CustomerForm from './pages/CustomerForm';
import Dash from './pages/Dash';
import Login from './pages/Login';
import Verify from './pages/Verify';
import Details from './pages/Details';
import { AuthProvider, PrivateRoute } from './providers/AuthProvider';


import { useLocation } from 'react-router-dom';
//import { useEffect } from 'react'; // import useEffect

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {
 // const location = useLocation();

  //const hideMenu = location.pathname === '/' ? true : false;
  

  // add this useEffect to keep the menu open on web browsers
//  useEffect(() => {
 //   const menu: any = document.querySelector('ion-menu');

   // if (menu && !hideMenu) {
   //   menu.close();
   // }
 // }, [hideMenu]);
//when={!hideMenu}





  return (
    <AuthProvider>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main" >
          
            <Menu/>
            
            <IonRouterOutlet id="main">
              <Route exact path="/" component={Login} />
              <Route exact path="/verify/:type" component={Verify} />
              <PrivateRoute exact path="/orders" component={Orders}></PrivateRoute>
              <PrivateRoute path="/orders/:id" component={Details}></PrivateRoute>
              <PrivateRoute exact path="/customers" component={Customers}></PrivateRoute>
              <PrivateRoute path="/customers/:id" component={CustomerForm}></PrivateRoute>
              <PrivateRoute path="/Page" exact={true} component={Page}></PrivateRoute>
              <PrivateRoute path="/Orders" exact={true} component={Orders}></PrivateRoute>
              <PrivateRoute path="/Dash" exact={true} component={Dash}></PrivateRoute>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </AuthProvider>
  );
};

export default App;
