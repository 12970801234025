import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, useIonViewWillEnter, IonButtons, IonButton, IonIcon, useIonRouter, IonFab, IonFabButton, IonList, IonItem, IonSearchbar, IonMenuButton, IonLabel } from '@ionic/react';
import { useParams } from 'react-router';
import { useAuth } from '../hooks/auth';
import { logOutOutline, addOutline } from 'ionicons/icons';
import { RealtimeUpdate, useData, Action } from '../hooks/custDat';
import useState from 'react-usestateref';

interface Customer {
  $id: string;
  tlowner: string;
  owner: string;
  custData: string

  
  $permissions: any;
  $content: any;
}
const Customers: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const { signOutUser } = useAuth();
  const navigation = useIonRouter();
  const { getCustomers, addCustomer, getCustomersRealtime } = useData();

  const [customers, setCustomers, customersRef] = useState<Customer[]>([]);
  const [dataList, setDataList] = useState<Customer[]>([]);
  const [filteredData, setFilteredData] = useState<Customer[]>([]);

  useIonViewWillEnter(async () => {
    const customers = await getCustomers();
    setCustomers(customers);
     
    getCustomersRealtime((result: RealtimeUpdate) => {
      if (result.type === Action.add) {
        setCustomers([...customersRef.current, result.data]);
    
        const customer = {
          $id: result.data.$id, 
          ...result.data.$permissions, 
          ...result.data.$content,
          //...result.data.custData
        // custData: JSON.stringify(JSON.parse(result.data.custData).replace(/\\/g, "") )// parse the custData field
         
        } as Customer;
        setDataList([...dataList, customer]);
        setFilteredData([...filteredData, customer]);
      } else {
        const filtered = customersRef.current.filter((customer: any) => customer.$id !== result.data.$id);
        setCustomers(filtered);
        setDataList(dataList.filter((customer: any) => customer.$id !== result.data.$id));
        setFilteredData(filteredData.filter((customer: any) => customer.$id !== result.data.$id));
      }
    });
  }); 
 

  const handleSearch = (event: CustomEvent) => {
    const searchTerm = event.detail.value!.toLowerCase();

    const filtered = customers.filter((customer:any) =>
      Object.values(customer).some((value:any) =>
        typeof value === "string" && value.toLowerCase().includes(searchTerm)
      )
    );
    setFilteredData(filtered);
    };

  const signOut = async () => {
    await signOutUser();
    navigation.push('/', 'back');
  };

  const createCustomer = async () => {
    const newCustomer = await addCustomer('My new customer');

    if (newCustomer) {
      navigation.push(`/customers/${newCustomer.$id}`, 'forward');
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}Customers</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={signOut}>
              <IonIcon icon={logOutOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar placeholder="Search" onIonChange={handleSearch} />



	  <IonList> 
					{filteredData.map((customer) => (
						<IonItem
							button
							key={customer.$id}
							routerLink={`/customers/${customer.$id}`}
							routerDirection="forward">
						    <IonLabel text-align="left" slot="start">{JSON.parse(customer.custData).name} </IonLabel>

						</IonItem>
					))}
				</IonList>
	  <IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={createCustomer}>
						<IonIcon icon={addOutline}></IonIcon>
					</IonFabButton>
				</IonFab>
		</IonContent>
	</IonPage>
  );
};
export default Customers;