import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	useIonViewWillEnter,
	IonButtons,
	IonButton,
	IonIcon,
	useIonRouter,
	IonItem,
	IonLabel,
	IonBackButton,
	IonInput,
	IonTextarea,
	useIonToast
} from '@ionic/react';
import { useData } from '../hooks/data';
import { useState } from 'react';
import { saveOutline } from 'ionicons/icons';

const Details: React.FC<any> = ({ match }) => {
	const navigation = useIonRouter();
	const { getOrderById, updateOrderById, deleteOrderById } = useData();
	const [order, setOrder] = useState<any>(null);
	const [showToast] = useIonToast();

	useIonViewWillEnter(async () => {
		const id = match.params.id;
		const order = await getOrderById(id);
		setOrder(order);
	});

	const updateOrder = async () => {
		try {
			await updateOrderById(order.$id, {
				text: order.text,
				title: order.title
			});
			showToast('Saved', 3000);
		} catch (e) {
			showToast('You are order allowed to update this order', 3000);
		}
	};

	const deleteOrder = async () => {
		try {
			await deleteOrderById(order.$id);
			navigation.push('/norders', 'back');
			showToast('Deleted', 3000);
		} catch (e) {
			showToast('You are order allowed to delete this order', 3000);
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color={'primary'}>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/norders"></IonBackButton>
					</IonButtons>
					<IonTitle>Order</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={updateOrder}>
							<IonIcon icon={saveOutline} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonItem>
					<IonLabel position="stacked">Title</IonLabel>
					<IonInput
						value={order?.title}
						onIonChange={(e: CustomEvent) => setOrder({ ...order, title: e.detail.value })}
					></IonInput>
				</IonItem>

				<IonItem>
					<IonLabel position="stacked">Text</IonLabel>
					<IonTextarea
						autoGrow
						value={order?.text}
						onIonChange={(e: CustomEvent) => setOrder({ ...order, text: e.detail.value })}
					></IonTextarea>
				</IonItem>

				<IonButton onClick={deleteOrder} expand="full" color="danger">
					Delete
				</IonButton>
			</IonContent>
		</IonPage>
	);
};
export default Details;