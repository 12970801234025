import { Account, Avatars, Client, Databases, Storage, Query } from 'appwrite';

const client = new Client().setEndpoint('https://tailit.mdpcsolutions.co.uk:443/v1').setProject('641101c6c2c69e5d2bdc');

const account = new Account(client);
const databases = new Databases(client);
const storage = new Storage(client);
const avatars = new Avatars(client);


export { client, account, databases, storage, avatars, Query };