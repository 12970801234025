import { useIonAlert } from '@ionic/react';
import { Permission, Role } from 'appwrite';
import { useContext } from 'react';
import { databases } from '../config/appwrite';
import { AuthContext } from '../providers/AuthProvider';
import { client } from '../config/appwrite';
import { Query } from '../config/appwrite';

const DB_ID = '641103bfce2a75cbbf88';
const CUST_COLLECTION_ID = '641ccc993e80bf110518';

export interface Customer {
	title?: string;
	text?: string;
}

export enum Action {
	add = 'add',
	remove = 'remove'
}
export interface RealtimeUpdate {
	type: Action;
	data: any;
}

export const useData = () => {
	const { authUser } = useContext(AuthContext);
	const [showAlert] = useIonAlert();

	const getCustomers = async (): Promise<any[]> => {
		try {
			return (await databases.listDocuments(DB_ID, CUST_COLLECTION_ID)).documents;
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return [];
		}
	};

	const getCustomerById = async (id: string) => {
		try {
			return databases.getDocument(DB_ID, CUST_COLLECTION_ID, id);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const addCustomer = (title: string) => {
		const userId = authUser.$id;

		try {
			return databases.createDocument(
				DB_ID,
				CUST_COLLECTION_ID,
				'unique()',
				{
					title
				},
				[
					Permission.read(Role.users()),
					Permission.update(Role.user(userId)),
					Permission.delete(Role.user(userId))
				]
			);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const updateCustomerById = async (id: string, data: Customer) => {
		try {
			return databases.updateDocument(DB_ID, CUST_COLLECTION_ID, id, data);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const deleteCustomerById = async (id: string) => {
		try {
			return databases.deleteDocument(DB_ID, CUST_COLLECTION_ID, id);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const getCustomersRealtime = (func: (data: any) => void) => {
		client.subscribe(`databases.${DB_ID}.collections.${CUST_COLLECTION_ID}.documents`, (data) => {
			if (data.events.includes('databases.*.collections.*.documents.*.delete')) {
				func({ type: Action.remove, data: data.payload });
			} else if (data.events.includes('databases.*.collections.*.documents.*.create')) {
				func({ type: Action.add, data: data.payload });
			}
		});
	};

	return {
		getCustomers,
		addCustomer,
		getCustomerById,
		updateCustomerById,
		deleteCustomerById,
		getCustomersRealtime
	};
};