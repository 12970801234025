import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
    useIonRouter,
  } from '@ionic/react'
  import { useContext } from 'react';
  import { useEffect } from 'react';
  import { useForm } from 'react-hook-form'
  import { useAuth } from '../hooks/auth'
  import { AuthContext } from '../providers/AuthProvider';

  
  
  const Login: React.FC = () => {
    const { register, handleSubmit, getValues } = useForm()
  
    const [present, dismiss] = useIonLoading()
    const [showAlert] = useIonAlert()
  
    const { sendMagicLink, createAccount, logIn } = useAuth()
    
    const { authUser, loadUser } = useContext(AuthContext);
	const navigation = useIonRouter();


    useEffect(() => {
  
        
		if (authUser) {
			navigation.push('/Dash', 'root');
		}
	}, [authUser]);


    const triggerMagicLink = async () => {
      present()
      const data = getValues()
      await sendMagicLink(data.email)
      dismiss()
      showAlert('Check your emails for the magic link!')
    }
  
    const signUp = async () => {
      await present()
      const data = getValues()
      try {
        await createAccount!(data.email, data.password)
        dismiss()
        showAlert('Please check your inbox to confirm your account!')
      } catch (e) {
        dismiss()
        showAlert('Login failed, invalid credentials')
      }
    }
  
    const signIn = async (data: any) => {
		await present();
		try {
			console.log('try login...');

			await logIn!(data.email, data.password);
			dismiss();

			// ADD THIS
			await loadUser!();
		} catch (e) {
			dismiss();
			showAlert('Login failed, invalid credentials');
		}
	};
   
    
  
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        
                    <img alt="logo" height="30"   src="/assets/tailIT2.png"></img>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonCard>
                    <IonCardContent>
                        <form onSubmit={handleSubmit(signIn)}>
                            <IonItem>
                                <IonLabel position="stacked">Email</IonLabel>
                                <IonInput
                                    type="email"
                                    placeholder="someone@example.com"
                                    {...register('email', {
                                        required: true
                                    })}
                                ></IonInput>
                            </IonItem>
    
                            <IonItem>
                                <IonLabel position="stacked">Password</IonLabel>
                                <IonInput
                                    type="password"
                                    placeholder="Your Password"
                                    {...register('password', {
                                        required: true
                                    })}
                                ></IonInput>
                            </IonItem>
                            <IonButton expand="full" type="submit" strong={true} className="ion-margin-top">
                                Sign in
                            </IonButton>
    
                        </form>

                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
  }
  
  export default Login