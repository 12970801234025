import { useIonAlert } from '@ionic/react';
import { Permission, Role } from 'appwrite';
import { useContext } from 'react';
import { databases } from '../config/appwrite';
import { AuthContext } from '../providers/AuthProvider';
import { client } from '../config/appwrite';
import { Query } from '../config/appwrite';

const DB_ID = '641103bfce2a75cbbf88';
const NOTES_COLLECTION_ID = '641103cccbdcf89e292c';

export interface Order {
	title?: string;
	text?: string;
}

export enum Action {
	add = 'add',
	remove = 'remove'
}
export interface RealtimeUpdate {
	type: Action;
	data: any;
}

export const useData = () => {
	const { authUser } = useContext(AuthContext);
	const [showAlert] = useIonAlert();

	const getOrders = async (): Promise<any[]> => {
		try {
			return (await databases.listDocuments(DB_ID, NOTES_COLLECTION_ID)).documents;
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return [];
		}
	};

	const getOrderById = async (id: string) => {
		try {
			return databases.getDocument(DB_ID, NOTES_COLLECTION_ID, id);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const addOrder = (title: string) => {
		const userId = authUser.$id;

		try {
			return databases.createDocument(
				DB_ID,
				NOTES_COLLECTION_ID,
				'unique()',
				{
					title
				},
				[
					Permission.read(Role.users()),
					Permission.update(Role.user(userId)),
					Permission.delete(Role.user(userId))
				]
			);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const updateOrderById = async (id: string, data: Order) => {
		try {
			return databases.updateDocument(DB_ID, NOTES_COLLECTION_ID, id, data);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const deleteOrderById = async (id: string) => {
		try {
			return databases.deleteDocument(DB_ID, NOTES_COLLECTION_ID, id);
		} catch (e) {
			showAlert(`There was an error with your request: ${e}`);
			return null;
		}
	};

	const getOrdersRealtime = (func: (data: any) => void) => {
		client.subscribe(`databases.${DB_ID}.collections.${NOTES_COLLECTION_ID}.documents`, (data) => {
			if (data.events.includes('databases.*.collections.*.documents.*.delete')) {
				func({ type: Action.remove, data: data.payload });
			} else if (data.events.includes('databases.*.collections.*.documents.*.create')) {
				func({ type: Action.add, data: data.payload });
			}
		});
	};

	return {
		getOrders,
		addOrder,
		getOrderById,
		updateOrderById,
		deleteOrderById,
		getOrdersRealtime
	};
};