import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	useIonViewWillEnter,
	IonButtons,
	IonButton,
	IonIcon,
	useIonRouter,
	IonItem,
	IonLabel,
	IonBackButton,
	IonInput,
	IonTextarea,
	useIonToast,
  IonCol,
  IonGrid,
  IonSelect,
  IonSelectOption,
  IonRow
} from '@ionic/react';
import { useData } from '../hooks/data';
import { useState } from 'react';
import { saveOutline } from 'ionicons/icons';
import AddressAutocomplete from '../components/AutocompleteInput';

interface CustomerData {
  name: string;
  company: string;
  email: string;
  phone: string;
  addresses: Address[];
  discount: string;
  priceBand: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface CustomerFormProps {
  onSubmit: (data: CustomerData) => void;
}

interface Address {
  type: string;
  line1: string;
  line2: string;
  city: string;
  postcode: string;
  country: string;
}


  


const CustomerForm: React.FC<CustomerFormProps> = ({ onSubmit }) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [discount, setDiscount] = useState("");
  const [priceBand, setPriceBand] = useState("");
  const [addresses, setAddresses] = useState<Address[]>([]);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const customerData: CustomerData = {
      name,
      company,
      email,
      phone,
      addresses,
      discount,
      priceBand,
    };
    onSubmit(customerData);
  };

  return (
    <IonPage>
    <IonContent>
    <form onSubmit={handleSubmit}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="stacked">Name</IonLabel>
              <IonInput
                type="text"
                value={name}
                onIonChange={(e) => setName(e.target.value as string)}
              />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="stacked">Company</IonLabel>
              <IonInput
                type="text"
                value={company}
                onIonChange={(e) => setCompany(e.target.value as string)}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.target.value as string)}
              />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="stacked">Phone</IonLabel>
              <IonInput
                type="tel"
                value={phone}
                onIonChange={(e) => setPhone(e.target.value as string)}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
                <IonCol>
                <AddressAutocomplete
  setFullAddress={(address: string) => {
    setAddresses([      {        type: "main",        line1: address,        line2: "",        city: "",        postcode: "",        country: "",      },    ]);
  }}
  onPlaceSelected={(place) => {
    const address = {
      type: "main",
      line1: place?.formatted_address ?? "",
      line2: "",
      city: place?.address_components?.find(
        (c: AddressComponent) => c.types.includes("locality")
      )?.long_name ?? "",
      postcode: place?.address_components?.find((c:AddressComponent) =>
        c.types.includes("postal_code")
      )?.long_name ?? "",
      country: place?.address_components?.find((c:AddressComponent) =>
        c.types.includes("country")
      )?.long_name ?? "",
    };
    setAddresses([address]);
  }}
/>

        </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
          <IonItem>
              <IonLabel position="stacked">Discount</IonLabel>
              <IonInput
                type="text"
                value={discount}
                onIonChange={(e) => setDiscount(e.target.value as string)}
              />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="stacked">Price Band</IonLabel>
              <IonInput
                type="text"
                value={priceBand}
                onIonChange={(e) => setPriceBand(e.target.value as string)}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Price Band</IonLabel>
              <IonSelect value={priceBand} onIonChange={(e) => setPriceBand(e.target.value)}>
                <IonSelectOption value="Low">Low</IonSelectOption>
                <IonSelectOption value="Medium">Medium</IonSelectOption>
                <IonSelectOption value="High">High</IonSelectOption>
              </IonSelect>
            </IonItem>
            </IonCol>
          </IonRow>
    </IonGrid>
<IonButton type="submit" expand="block" className="ion-margin-top">
            Create Customer
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default CustomerForm;
