import React, { useState } from "react";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";

interface AddressAutocompleteProps {
  setFullAddress: (address: string) => void;
  onPlaceSelected: (place: any) => void;
}

const AddressAutocomplete = ({ setFullAddress, onPlaceSelected }: AddressAutocompleteProps) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const formattedAddress = place?.formatted_address;
      if (formattedAddress) {
        setFullAddress(formattedAddress);
      }
      if (onPlaceSelected) {
        onPlaceSelected(place);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: -3.745,
    lng: -38.523,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCTJjpgzSD-ijFbSpoBS0MadEM56U_H2EI" libraries={["places"]}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            placeholder="Enter your address"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </Autocomplete>
      </GoogleMap>
    </LoadScript>
  );
};

export default AddressAutocomplete;
