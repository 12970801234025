
//import { useState, useEffect } from 'react';
import { 	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	useIonViewWillEnter,
	IonButtons,
	IonButton,
	IonIcon,
	useIonRouter,
	IonFab,
	IonFabButton,
	IonList,
	IonItem,
	IonSearchbar,
	IonMenuButton,
	IonLabel } from '@ionic/react';
//import { Appwrite } from 'appwrite';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import { useAuth } from '../hooks/auth';
import { logOutOutline, addOutline } from 'ionicons/icons';
import { RealtimeUpdate, useData, Action } from '../hooks/data';
import useState from 'react-usestateref';

interface Data {
  $id: string;
  JobNo: string;
  Customer: string;
  Ref: string;
}

const Orders: React.FC = () => {
  //const [dataList, setDataList] = useState<Data[]>([]);
  const [filteredData, setFilteredData] = useState<Data[]>([]);
  const { name } = useParams<{ name: string; }>();
  const { signOutUser } = useAuth();
  const navigation = useIonRouter();
  const {getOrders, addOrder, getOrdersRealtime } = useData();
  const [orders, setOrders, ordersRef] = useState<any[]>([]);
  const [dataList,setDataList] = useState<any[]>([]);

  useIonViewWillEnter(async () => {

	 const orders = await getOrders();
	
	setOrders(orders);
	getOrdersRealtime((result: RealtimeUpdate) => {
		if (result.type === Action.add) {
			setOrders([...ordersRef.current, result.data]);
			
			const data = result.data.map((order:any) => ({ $id: order.$id, ...order.$permissions, ...order.$content })) as Data[];
			setDataList(data);
			setFilteredData(data);

		} else {
			const filtered = ordersRef.current.filter((order:any) => order.$id !== result.data.$id);
			setOrders(filtered);
		}
	});
});

const signOut = async () => {
	await signOutUser();
	navigation.push('/', 'back');
};

const createOrder = async () => {
	const newOrder = await addOrder('My new order');

	if (newOrder) {
		navigation.push(`/orders/${newOrder.$id}`, 'forward');
	}
};






const handleSearch = (event: CustomEvent) => {
    const searchTerm = event.detail.value!.toLowerCase();

    const filtered = orders.filter((order:any) =>
      Object.values(order).some((value:any) =>
        typeof value === "string" && value.toLowerCase().includes(searchTerm)
      )
    );
    setFilteredData(filtered);
};

  return (
    <IonPage>
	 <IonHeader>
				<IonToolbar>
					          <IonButtons slot="start">
								<IonMenuButton />
							</IonButtons>
							<IonTitle>{name}Orders</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={signOut}>
							<IonIcon icon={logOutOutline} slot="icon-only"></IonIcon>
						</IonButton>
					</IonButtons>
				
				</IonToolbar>
	 </IonHeader>
	 <IonContent>
		<IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name={name} />

      <IonSearchbar placeholder="Search" onIonChange={handleSearch} />



	  <IonList> 
					{filteredData.map((order) => (
						<IonItem
							button
							key={order.$id}
							routerLink={`/orders/${order.$id}`}
							routerDirection="forward"
						>
						    <IonLabel text-align="left"> {order.JobNo} - {order.Customer} - {order.Ref} </IonLabel>
						
						</IonItem>
					))}
				</IonList>
	  <IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={createOrder}>
						<IonIcon icon={addOutline}></IonIcon>
					</IonFabButton>
				</IonFab>
		</IonContent>
	</IonPage>
  );
};
export default Orders;